/* eslint-disable react/jsx-no-target-blank */
import axios from 'axios'
import {useCallback, useEffect, useState} from 'react'
import {AsideMenuItem} from './AsideMenuItem'
// import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

interface Routes {
    id: string
    title: string
    root?: boolean
    icon?: string
    page: string
    submenu?: Routes[]
}
interface DataMenu {
    id: string
    section?: string
    routes: Routes[]
}

export function AsideMenuMain() {
    const [dataMenu, setDataMenu] = useState<DataMenu[]>([])

    const getMenu = useCallback(async () => {
        const {data} = await axios.get('/get_routes')
        if (data.success === true) {
            setDataMenu(data.pages)
        } else {
            throw new Error('Error in request menu')
        }
    }, [])

    useEffect(() => {
        getMenu()
    }, [getMenu])
    function Line({title}: {title?: string}) {
        return (
            <div className='menu-item'>
                <div className='menu-content py-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                        {title}
                    </span>
                </div>
            </div>
        )
    }
    return (
        <>
            {dataMenu?.map((item, index) => (
                <div key={'divMenu.' + index}>
                    <Line title={item.section} key={index + '.line'} />
                    {item.routes.map((route, i) =>
                        !route.root ? (
                            <AsideMenuItemWithSub
                                key={i + '.sub'}
                                to={route.page}
                                title={route.title}
                                fontIcon='bi-archive'
                                icon={route.icon}
                            >
                                {route.submenu?.map((subItem, subIndex) => (
                                    <AsideMenuItem
                                        key={index + '.' + subIndex}
                                        to={subItem.page}
                                        title={subItem.title}
                                        hasBullet={true}
                                    />
                                ))}
                            </AsideMenuItemWithSub>
                        ) : (
                            <AsideMenuItem
                                key={i + '.item'}
                                to={route.page}
                                icon={route.icon}
                                title={route.title}
                                fontIcon='bi-app-indicator'
                            />
                        )
                    )}
                </div>
            ))}
        </>
    )
}
