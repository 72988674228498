import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function DashboardRoutes() {
    /*Rotas para Historico de Atividades*/
    const DashboardAdministrative = lazy(
        () => import('../../pages/dashboard/DashboardAdministrative')
    )
    const EventCalendar = lazy(() => import('../../pages/dashboard/EventCalendar'))

    return (
        <Switch>
            {/*Rotas para Historico de Atividades*/}
            <Route path='/painel/administrativo' component={DashboardAdministrative} />
            <Route path='/painel/calendario' component={EventCalendar} />
        </Switch>
    )
}
