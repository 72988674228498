import {PreEventModel} from '../../../../shared/models/products/event'
import {parseDate, parseMoneyDotted} from '../../../../utils'
import {DividerStoreComponent} from '../../../../widgets/dividers/DividerStoreComponent'

interface Props {
    preEvent: PreEventModel
}

export function StoreDataEventInfo({preEvent}: Props) {
    return (
        <>
            {preEvent.im_banner_path !== null && (
                <>
                    <DividerStoreComponent dsClass='dark' title='Imagem' />
                    <div className='row mb-5 justify-content-center'>
                        <div className='col-lg-4 text-center'>
                            <img
                                src={preEvent.im_banner_path || '/media/logos/favicon.png'}
                                alt='Foto'
                                className='w-auto h-200px rounded'
                            />
                        </div>
                    </div>
                </>
            )}
            <DividerStoreComponent dsClass='dark' title='Informações do evento' />
            <div className='row mb-2'>
                <div className='col-lg-8'>
                    <label>Nome</label>
                    <h3>{preEvent.nm_pre_event}</h3>
                </div>
                <div className='col-lg-4'>
                    <label>Período</label>
                    <h3>
                        {parseDate(preEvent.dt_start)} - {parseDate(preEvent.dt_end)}
                    </h3>
                </div>
            </div>
            <DividerStoreComponent dsClass='dark' title='Negociação' />
            <div className='row mb-2'>
                <div className='col-lg-2'>
                    <label>Mínimo?</label>
                    <h3>
                        {preEvent.negotiation.qty_min === '1'
                            ? 'Sim - ' + parseMoneyDotted(preEvent.negotiation.vn_min)
                            : 'Não'}
                    </h3>
                    <label>Percentual</label>
                    <h3>{preEvent.negotiation.vn_percent}%</h3>
                    <label>Setup?</label>
                    <h3>
                        {preEvent.negotiation.qty_setup === '1'
                            ? 'Sim - ' + parseMoneyDotted(preEvent.negotiation.vn_setup)
                            : 'Não'}
                    </h3>
                </div>
                <div className='col-lg-2'>
                    <label>Bonificações</label>
                    <ul>
                        <li className='h3'>{preEvent.negotiation.qty_pos_k} POS</li>
                        <li className='h3'>{preEvent.negotiation.qty_bag_k} mochilas</li>
                        <li className='h3'>{preEvent.negotiation.qty_card_k} cartões</li>
                    </ul>
                </div>
                <div className='col-lg-5'>
                    <label>Contratações adicionais</label>
                    <ul>
                        {preEvent.negotiation.qty_pos_c === 0 &&
                            preEvent.negotiation.qty_bag_c === 0 &&
                            preEvent.negotiation.qty_card_c === 0 &&
                            preEvent.negotiation.qty_support_c === 0 && (
                                <li className='h3'>Nenhuma contratação adicional</li>
                            )}
                        {preEvent.negotiation.qty_pos_c > 0 && (
                            <li className='h3'>
                                {preEvent.negotiation.qty_pos_c} POS |{' '}
                                {parseMoneyDotted(preEvent.negotiation.vn_pos_c)}
                                {' - '}
                                {preEvent.negotiation.ds_pos_c}
                            </li>
                        )}
                        {preEvent.negotiation.qty_bag_c > 0 && (
                            <li className='h3'>
                                {preEvent.negotiation.qty_bag_c} mochilas |{' '}
                                {parseMoneyDotted(preEvent.negotiation.vn_bag_c)}
                                {' - '}
                                {preEvent.negotiation.ds_bag_c}
                            </li>
                        )}
                        {preEvent.negotiation.qty_card_c > 0 && (
                            <li className='h3'>
                                {preEvent.negotiation.qty_card_c} cartões |{' '}
                                {parseMoneyDotted(preEvent.negotiation.vn_card_c)}
                                {' - '}
                                {preEvent.negotiation.ds_card_c}
                            </li>
                        )}
                        {preEvent.negotiation.qty_support_c > 0 && (
                            <li className='h3'>
                                {preEvent.negotiation.qty_support_c} pessoas presenciais |{' '}
                                {parseMoneyDotted(preEvent.negotiation.vn_support_c)}
                                {' - '}
                                {preEvent.negotiation.ds_support_c}
                            </li>
                        )}
                    </ul>
                </div>
                <div className='col-lg-3'>
                    <label>Observação</label>
                    <h3>{preEvent.negotiation.ds_observation ?? '-'}</h3>
                </div>
            </div>
        </>
    )
}
