import {Formik} from 'formik'
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import ButtonAuthSubmit from '../../../../_metronic/layout/components/buttons/ButtonAuthSubmit'
import {TextInput} from '../../../widgets/form'
import {login} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Entre com um e-mail válido').required('E-mail é obrigatório'),
    password: Yup.string()
        .min(6, 'Mínimo 6 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Senha é obrigatório'),
})

const initialValues = {
    email: '',
    password: '',
}

export function Login() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    async function handleSubmit(values: typeof initialValues) {
        try {
            setLoading(true)
            const {data} = await login(values)
            dispatch(
                auth.actions.login(data.data.tokens.access_token, data.data.tokens.refresh_token)
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <form className='form w-100' onSubmit={props.handleSubmit} noValidate>
                    <div className='mb-5 bg-light p-5 rounded text-center'>
                        <p className='text-dark h3 text-justify'>
                            Bem-vindo(a) a Kachin Administrativo!
                            <br />
                            Gestão para seu evento.
                        </p>
                    </div>
                    <div className='fv-row mb-5'>
                        <TextInput
                            name='email'
                            formik={props}
                            label='E-mail'
                            type='email'
                            placeholder='Ex: contato@kachin.com.br'
                        />
                    </div>
                    <div className='fv-row mb-5'>
                        <TextInput
                            name='password'
                            type='password'
                            label='Senha'
                            formik={props}
                            placeholder='********'
                        />
                    </div>
                    <div className='text-center'>
                        <ButtonAuthSubmit loading={loading} buttonTitle='Accessar plataforma' />
                    </div>
                </form>
            )}
        </Formik>
    )
}
