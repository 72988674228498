import * as Yup from 'yup'
import { CollectionMethodEnum } from '../../../../shared/enums/CollectionMethodEnum'

export interface ValuesUpdateNegotiationPreEvent {
    qty_min: string
    vn_min: string
    qty_percent: string
    vn_percent: string
    qty_setup: string
    vn_setup: string
    qty_pos_k: string
    qty_bag_k: string
    qty_card_k: string

    qty_pos_c: string
    vn_pos_c: string
    ds_pos_c: string
    qty_bag_c: string
    vn_bag_c: string
    ds_bag_c: string
    qty_card_c: string
    vn_card_c: string
    ds_card_c: string
    qty_support_c: string
    vn_support_c: string
    ds_support_c: string

    ds_observation: string
}

export const vsUpdateNegotiationPreEvent = Yup.object({
    qty_min: Yup.string().required('Campo obrigatório!'),
    vn_min: Yup.string().when('qty_min', {
        is: '1',
        then: Yup.string().required('Campo obrigatório!'),
    }),
    vn_percent: Yup.string().required('Campo obrigatório!'),
    qty_setup: Yup.string().required('Campo obrigatório!'),
    vn_setup: Yup.string().when('qty_setup', {
        is: '1',
        then: Yup.string().required('Campo obrigatório!'),
    }),

    qty_pos_k: Yup.string().required('Campo obrigatório!'),
    qty_bag_k: Yup.string().required('Campo obrigatório!'),
    qty_card_k: Yup.string().required('Campo obrigatório!'),

    qty_pos_c: Yup.string().required('Campo obrigatório!'),
    ds_pos_c: Yup.string().when('qty_pos_c', {
        is: (qty_pos_c: string) => Number(qty_pos_c) > 1,
        then: Yup.string().required('Campo obrigatório!'),
    }),
    vn_pos_c: Yup.string().when('ds_pos_c', {
        is: (ds_pos_c: string) =>
            [CollectionMethodEnum.PerUnit, CollectionMethodEnum.SingleValue].includes(
                ds_pos_c as CollectionMethodEnum
            ),
        then: Yup.string().required('Campo obrigatório!'),
    }),
    qty_bag_c: Yup.string().required('Campo obrigatório!'),
    ds_bag_c: Yup.string().when('qty_bag_c', {
        is: (qty_pos_c: string) => Number(qty_pos_c) > 1,
        then: Yup.string().required('Campo obrigatório!'),
    }),
    vn_bag_c: Yup.string().when('ds_bag_c', {
        is: (ds_bag_c: string) =>
            [CollectionMethodEnum.PerUnit, CollectionMethodEnum.SingleValue].includes(
                ds_bag_c as CollectionMethodEnum
            ),
        then: Yup.string().required('Campo obrigatório!'),
    }),
    qty_card_c: Yup.string().required('Campo obrigatório!'),
    ds_card_c: Yup.string().when('qty_card_c', {
        is: (qty_pos_c: string) => Number(qty_pos_c) > 1,
        then: Yup.string().required('Campo obrigatório!'),
    }),
    vn_card_c: Yup.string().when('ds_card_c', {
        is: (ds_card_c: string) =>
            [CollectionMethodEnum.PerUnit, CollectionMethodEnum.SingleValue].includes(
                ds_card_c as CollectionMethodEnum
            ),
        then: Yup.string().required('Campo obrigatório!'),
    }),
    qty_support_c: Yup.string().required('Campo obrigatório!'),
    ds_support_c: Yup.string().when('qty_support_c', {
        is: (qty_pos_c: string) => Number(qty_pos_c) > 1,
        then: Yup.string().required('Campo obrigatório!'),
    }),
    vn_support_c: Yup.string().when('ds_support_c', {
        is: (ds_support_c: string) =>
            [CollectionMethodEnum.PerUnit, CollectionMethodEnum.SingleValue].includes(
                ds_support_c as CollectionMethodEnum
            ),
        then: Yup.string().required('Campo obrigatório!'),
    }),

    ds_observation: Yup.string().nullable(),
})

