import * as Yup from 'yup'

export interface ValuesUpdateInfoPreEvent {
    nm_pre_event: string
    dt_start: string
    dt_end: string
    im_banner_path: string|null
}

export const vsUpdateInfoPreEvent = Yup.object({
    nm_pre_event: Yup.string().required('Campo obrigatório!'),
    dt_start: Yup.date().required('Campo obrigatório!'),
    dt_end: Yup.date().required('Campo obrigatório!'),
})

