import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function MobileRoutes() {
    /*Rotas para Usuários*/
    const DatatableUsers = lazy(() => import('../../pages/mobile/users/DatatableUser'))
    const ShowUser = lazy(() => import('../../pages/mobile/users/ShowUser'))

    return (
        <Switch>
            {/*Rotas para Usuários*/}
            <Route path='/aplicativo/usuarios/:id' component={ShowUser} />
            <Route path='/aplicativo/usuarios' component={DatatableUsers} />
        </Switch>
    )

}
