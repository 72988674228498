import * as Yup from 'yup'
import { checkCnpj, checkCpf } from '../../../../utils'

export interface ValuesUpdateDataPreEvent {
    type_contractor: string
    nm_contractor: string
    doc_contractor: string

    ds_zipcode: string
    ds_street: string
    ds_number: string
    ds_complement: string
    ds_neighborhood: string
    ds_city: string
    ds_state: string

    nm_agent: string
    doc_agent: string
    ds_agent_phone: string
    ds_agent_email: string
    
    nm_witness: string
    doc_witness: string
    ds_witness_phone: string
    ds_witness_email: string

    ds_contractor_bank: string
    ds_contractor_branch: string
    ds_contractor_account: string
    ds_contractor_pix: string
}

export const vsUpdateDataPreEvent = Yup.object({
    type_contractor: Yup.string().required('Campo obrigatório!'),
    nm_contractor: Yup.string().required('Campo obrigatório!'),
    doc_contractor: Yup.string()
        .required('Campo obrigatório!')
        .test('is-cpf-or-cnpj', 'Documento inválido', function(value) {
            if (!value) return false;
            const cleanedValue = value.replace(/[^\d]+/g, '');
            return cleanedValue.length === 11
                ? checkCpf(cleanedValue)
                : checkCnpj(cleanedValue);
        }),
    ds_zipcode: Yup.string().required('Campo obrigatório!'),
    ds_street: Yup.string().required('Campo obrigatório!'),
    ds_number: Yup.string().required('Campo obrigatório!'),
    ds_complement: Yup.string().nullable(),  // Pode ser null, caso seja opcional
    ds_neighborhood: Yup.string().required('Campo obrigatório!'),
    ds_city: Yup.string().required('Campo obrigatório!'),
    ds_state: Yup.string().required('Campo obrigatório!'),
    nm_agent: Yup.string().required('Campo obrigatório!'),
    doc_agent: Yup.string()
        .required('Campo obrigatório!')
        .test('is-cpf', 'CPF inválido', function(value) {
            if (!value) return false;
            const cleanedValue = value.replace(/[^\d]+/g, '');
            return checkCpf(cleanedValue);
        }),
    ds_agent_phone: Yup.string().required('Campo obrigatório!'),
    ds_agent_email: Yup.string().required('Campo obrigatório!').email('E-mail inválido!'),
    ds_contractor_bank: Yup.string().required('Campo obrigatório!'),
    ds_contractor_branch: Yup.string().required('Campo obrigatório!'),
    ds_contractor_account: Yup.string().required('Campo obrigatório!'),
    ds_contractor_pix: Yup.string().nullable(),
    nm_witness: Yup.string().required('Campo obrigatório!'),
    doc_witness: Yup.string()
        .required('Campo obrigatório!')
        .test('is-cpf', 'CPF inválido', function(value) {
            if (!value) return false;
            const cleanedValue = value.replace(/[^\d]+/g, '');
            return checkCpf(cleanedValue);
        }),
    ds_witness_phone: Yup.string().required('Campo obrigatório!'),
    ds_witness_email: Yup.string().required('Campo obrigatório!').email('E-mail inválido!')
})

