import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function AccessRoutes() {
    /*Rotas para Usuários*/
    const DatatableAdmins = lazy(() => import('../../pages/access/admins/DatatableAdmin'))
    const StoreAdmin = lazy(() => import('../../pages/access/admins/StoreAdmin'))
    const ShowAdmin = lazy(() => import('../../pages/access/admins/ShowAdmin'))
    const UpdateAdmin = lazy(() => import('../../pages/access/admins/UpdateAdmin'))

    /*Rotas para Usuários*/
    const DatatablePrivileges = lazy(() => import('../../pages/access/privileges/DatatablePrivilege'))
    const StorePrivilege = lazy(() => import('../../pages/access/privileges/StorePrivilege'))
    const UpdatePrivilege = lazy(() => import('../../pages/access/privileges/UpdatePrivilege'))

    return (
        <Switch>
            {/*Rotas para Usuários*/}
            <Route path='/acessos/usuarios/cadastrar' component={StoreAdmin} />
            <Route path='/acessos/usuarios/:id/atualizar' component={UpdateAdmin} />
            <Route path='/acessos/usuarios/:id' component={ShowAdmin} />
            <Route path='/acessos/usuarios' component={DatatableAdmins} />

            {/*Rotas para Perfis*/}
            <Route path='/acessos/perfis/:id/atualizar' component={UpdatePrivilege} />
            <Route path='/acessos/perfis/cadastrar' component={StorePrivilege} />
            <Route path='/acessos/perfis' component={DatatablePrivileges} />

        </Switch>
    )

}
