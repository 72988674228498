import {toAbsoluteUrl} from '../../../_metronic/helpers'

interface Props {
    children?: React.ReactNode
}

export function DefaultUnauthorizedContainer({children}: Props) {
    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/bgs/bg-login.png')})`,
                backgroundSize: 'cover',
            }}
        >
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                <div
                    className='bg-white rounded shadow-sm mx-auto container-fluid p-0'
                    style={{maxWidth: '1200px'}}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}
