/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import { UserModel } from '../../../../app/models'
import {RootState} from '../../../../setup'

const HeaderUserMenu: FC = () => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='symbol symbol-35px me-5'>
                        <span className='symbol-label font-size-h5 font-weight-bold'>
                            {user.name?.split('')[0]}
                        </span>
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='fw-bolder d-flex align-items-center fs-5'>
                            {user.name}
                        </div>
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-5'>
                <Link to={'/perfil'} className='menu-link px-5'>
                    Meu Perfil
                </Link>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-5'>
                <Link to='/logout' className='menu-link px-5'>
                    Sair
                </Link>
            </div>
        </div>
    )
}

export {HeaderUserMenu}
