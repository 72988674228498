interface Props {
    defaultClass: string
    title: string
    titleSize?: string
    icon: string
    iconSize?: string
}

export function CardAlertInformation({
    defaultClass,
    title,
    titleSize = 'fs-6',
    icon,
    iconSize = 'fs-1',
}: Props) {
    return (
        <div className={`card bg-light-${defaultClass} mb-5`}>
            <div className='card-header'>
                <div className='card-title'>
                    <i className={`${icon} ${iconSize} text-${defaultClass} me-2`}></i>
                    <h4 className={`text-${defaultClass} ${titleSize}`}>{title}</h4>
                </div>
            </div>
        </div>
    )
}
