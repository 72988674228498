interface CropImage {
    base64: string
    startX: number
    startY: number
    width: number
    height: number
    maxWidth?: number
    maxHeight?: number
}

export function cropImage({
    base64,
    height,
    width,
    startX,
    startY,
    maxHeight = 256,
    maxWidth = 256,
}: CropImage): Promise<string> {
    return new Promise((resolve) => {
        const image = new Image()
        image.onload = function () {
            const canvas = document.createElement('canvas')
            const contexto = canvas.getContext('2d')
            canvas.width = maxWidth
            canvas.height = maxHeight
            contexto?.drawImage(image, startX, startY, width, height, 0, 0, maxWidth, maxHeight)
            const base64Cortado = canvas.toDataURL('image/png')
            resolve(base64Cortado)
        }
        image.src = base64
    })
}

export const photoUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    callback: (base64: string) => void
) => {
    e.preventDefault()
    const reader = new FileReader()
    if (!e.target.files) return
    const file = e.target.files[0]
    if (reader !== undefined && file !== undefined) {
        reader.onloadend = () => {
            callback(reader.result as string)
        }
        reader.readAsDataURL(file)
    }
}

export const urlToBase64 = async (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = img.width
            canvas.height = img.height
            ctx?.drawImage(img, 0, 0)
            const dataURL = canvas.toDataURL('image/png')
            resolve(dataURL)
        }
        img.onerror = () => {
            reject('Error on load image!')
        }
        img.src = url
    })
}

export const imageToBase64 = async (img: HTMLImageElement): Promise<string> => {
    return new Promise((resolve, reject) => {
        img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = img.width
            canvas.height = img.height
            ctx?.drawImage(img, 0, 0)
            const dataURL = canvas.toDataURL('image/png')
            resolve(dataURL)
        }
        img.onerror = () => {
            reject('Error on load image!')
        }
    })
}
