export default function parseMoney(money = '') {
    return 'R$ ' + money.replace('.', ',')
}

export function parseMoneyDotted(money = '') {
    if(money !== '') {
        const formattedMoney = Number(money)
        return formattedMoney.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    }

    return money;
}