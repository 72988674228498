import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function WarehouseRoutes() {
    /*Rotas para POS*/
    const DatatableProvider = lazy(
        () => import('../../pages/warehouse/pos/providers/DatatableProvider')
    )
    const DatatableMachine = lazy(
        () => import('../../pages/warehouse/pos/machines/DatatableMachine')
    )

    return (
        <Switch>
            {/*Rotas para POS*/}
            <Route path='/almoxarifado/pos/fornecedores' component={DatatableProvider} />
            <Route path='/almoxarifado/pos/maquinas' component={DatatableMachine} />
        </Switch>
    )
}
