import clsx from 'clsx'
import {Field, FieldProps, FormikProps} from 'formik'
import React, {useState} from 'react'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    label: string
    required?: boolean
    touched?: boolean | undefined
    error?: string | undefined
    formik?: FormikProps<any>
}

export function DocumentInput({name, label, required, formik, touched, error}: Props) {
    const [preview, setPreview] = useState<string | ArrayBuffer | null>('')
    console.log(error)

    return (
        <Field name={name}>
            {({form}: FieldProps) => {
                const fileUpload = (e: any) => {
                    e.preventDefault()
                    const reader = new FileReader()
                    const file = e.target.files[0]
                    if (reader !== undefined && file !== undefined) {
                        reader.onloadend = (readerEvt: any) => {
                            setPreview(file.name)
                            form.setFieldValue(name, reader.result)
                        }
                        reader.readAsDataURL(file)
                    }
                }
                return (
                    <>
                        <label
                            htmlFor={name}
                            className='form-label fs-6 fw-bolder d-flex flex-column p-0 m-0 position-relative'
                        >
                            <div className='d-flex flex-row'>
                                {label}
                                {required && <span className='text-danger'>*</span>}
                            </div>
                            <div className='d-flex flex-row align-items-center'>
                                <label
                                    htmlFor={name}
                                    className='btn btn-primary p-0 m-0 rounded-circle h-30px w-30px d-flex align-items-center justify-content-center bottom-0 border border-1 border-gray'
                                >
                                    <i className='fas fa-upload p-0' />
                                </label>
                                <div
                                    className={clsx(
                                        `h-30px w-100 d-flex align-items-center ms-1 form-control form-control-lg form-control-solid ${
                                            error ? 'is-invalid' : ''
                                        }`
                                    )}
                                >
                                    <p className='h-100 overflow-hidden m-0'>{preview}</p>
                                </div>
                            </div>
                            {error && (
                                <span
                                    style={{fontWeight: 'normal', fontSize: '13px'}}
                                    role='alert'
                                    className='text-danger font-thin'
                                >
                                    {error}
                                </span>
                            )}
                        </label>
                        <input
                            type='file'
                            accept='application/pdf'
                            name={name}
                            id={name}
                            onChange={fileUpload}
                            className='d-none'
                        />
                    </>
                )
            }}
        </Field>
    )
}
