export function parseDate(date: string) {
    const [year, month, day] = date.split('-')
    return day ? day + '/' + month + '/' + year : null
}

export function parseDateTime(datetime: string) {
    const [date, time] = datetime.split(' ')
    const [year, month, day] = date.split('-')
    return day ? `${day}/${month}/${year} ${time}` : null
}
