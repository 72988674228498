interface Props {
    title: String
    children?: React.ReactNode
}

export function DefaultCardWithTitleContainer({title, children}: Props) {
    return (
        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <h3 className='card-label'>{title}</h3>
                </div>
            </div>

            <div className='card-body'>{children}</div>
        </div>
    )
}
