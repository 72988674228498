import axios from 'axios'
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3'

interface Message {
    id: string
    type: string
    notifiable_type: string
    notifiable_id: number
    color?: string
    icon?: string
    data: {
        title: string
        message: string
        description: string
    }
    read_at: null | Date
    created_at: string
    updated_at: Date
}

const Topbar: FC = () => {

    const [notifications, setNotifications] = useState<Message[]>([])
    const [someNotRead, setSomeNotRead] = useState(false)

    async function getNotification() {
        const {data} = await axios.get('/profile/notifications')
        setNotifications(data.data.notifications)
        setSomeNotRead(data.data?.notifications?.some((item: Message) => !item.read_at))
    }
    useEffect(() => {
        getNotification()
    }, [])

    return (
        <div className='d-flex align-items-stretch flex-shrink-0'>
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                <div
                    className={'btn btn-icon btn-active-success text-white position-relative'}
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'
                >
                    <i className='fas fa-bell text-white'></i>
                    {someNotRead && (
                        <>
                            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
                            <span className='pulse-ring'></span>
                        </>
                    )}
                </div>
                <HeaderNotificationsMenu notifications={notifications} />
            </div>
            <div
                className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                id='kt_header_user_menu_toggle'
            >
                <div
                    className={'cursor-pointer symbol btn btn-icon btn-active-success'}
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'
                >
                    <i className='fas fa-user fa-2x text-white'></i>
                </div>
                <HeaderUserMenu />
            </div>
        </div>
    )
}

export {Topbar}