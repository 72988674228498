import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import {logout} from './redux/AuthCRUD'
import * as auth from './redux/AuthRedux'

export function Logout() {
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        async function logoutUser() {
            await logout()
            dispatch(auth.actions.logout())
            toast.success('Sucesso ao deslogar usuário!')
            history.push('autenticacao/entrar')
        }
        logoutUser()
    }, [dispatch, history])

    return <></>
}