import {Link} from 'react-router-dom'
import Notification from './Notification'

interface Message {
    id: string
    type: string
    notifiable_type: string
    notifiable_id: number
    color?: string
    icon?: string
    data: {
        title: string
        message: string
        description: string
    }
    read_at: null | Date
    created_at: string
    updated_at: Date
}

interface Props {
    notifications: Message[]
}

export function HeaderNotificationsMenu({notifications}: Props) {
    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px bg-white'
            data-kt-menu='true'
        >
            <div className='d-flex flex-column bgi-no-repeat rounded-top bg-primary'>
                <h3 className='text-white fw-bold px-9 my-10'>
                    Notificações{' '}
                    <span className='fs-8 opacity-75 ps-3'>
                        {notifications.filter((item) => !item.read_at).length} notificações
                    </span>
                </h3>
            </div>

            <div className='scroll-y mh-325px my-3 px-2'>
                {notifications?.length !== 0 ? (
                    notifications?.map((notification, index) => (
                        <Notification
                            created_at={notification.created_at}
                            title={notification.data.title}
                            message={notification.data.message}
                            color={notification.color}
                            icon={notification.color}
                            key={`not-${index}`}
                        />
                    ))
                ) : (
                    <p className='text-center my-5'>
                        Nenhuma notificação disponível! <br /> Você não possui notificações.
                    </p>
                )}
            </div>

            <div className='py-3 text-center border-top'>
                <Link
                    to='/perfil/notificacoes'
                    className='btn btn-color-gray-600 btn-active-color-primary align-items-center'
                >
                    Ver todas
                        <i className='ms-2 fas fa-arrow-circle-right'></i>
                </Link>
            </div>
        </div>
    )
}
