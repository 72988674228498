interface Props {
    dsClass: String
    title: String
    hasMargin?: boolean
    isCenter?: boolean
}

export function DividerStoreComponent({dsClass, title, hasMargin = true, isCenter = true}: Props) {
    return (
        <div
            className={`row ${hasMargin ? 'mb-5' : ''} ${
                isCenter ? 'justify-content-center' : ''
            } `}
        >
            <span
                style={{width: 'fit-content'}}
                className={`badge-light-${dsClass} text-center m-0 py-2 px-5 rounded fw-bold fs-5`}
            >
                {title}
            </span>
        </div>
    )
}
