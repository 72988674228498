import clsx from "clsx";

interface Props {
    color?: string;
    icon?: string;
    title: string;
    message: string;
    created_at: string;
}
export default function Notification({
    color = "primary", 
    icon="fas fa-bell", 
    title, 
    message, 
    created_at
}:Props){
    const date = new Date(created_at);
    const month_names = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    return(
        <div className='d-flex flex-stack py-4'>
            <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                    <span className={clsx('symbol-label', `bg-light-${color}`)}>
                    {' '}
                    <i className={`${icon} text-${color}`} style={{fontSize:'18px'}}></i>
                    </span>
                </div>

                <div className='mb-0 me-2'>
                    <span className='fs-6 text-gray-800 fw-bolder'>
                        {title}
                    </span>
                    <div className='text-gray-400 fs-7'>{message}</div>
                </div>
            </div>

            <span className='badge badge-light fs-8'>{`${date.getDate()} ${month_names[date.getMonth()]}`}</span>
        </div>
    )
}