import axios from 'axios'
import {LoginResponse} from '../models/AuthModel'
import {ProfileResponse} from '../models/ProfileModel'
export const GET_USER_BY_ACCESSTOKEN_URL = `/profile/show`
export const LOGIN_URL = `/auth/login`
export const LOGOUT_URL = `/auth/logout`
export const REGISTER_URL = `/auth/register`
export const REQUEST_PASSWORD_URL = `/auth/forgot_password`
export const REQUEST_TOKEN_URL = `/auth/check_hash`
export const RESET_PASSWORD_URL = `/auth/reset_password`

// Server should return AuthModel
interface Login {
    email: string
    password: string
}
export function login({email, password}: Login) {
    return axios.post<LoginResponse>(LOGIN_URL, {email, password})
}

export function logout() {
    return axios.get(LOGOUT_URL)
}

// Server should return AuthModel
export function register(
    name: string,
    email: string,
    password: string,
    password_confirmation: string
) {
    return axios.post(REGISTER_URL, {
        name,
        email,
        password,
        password_confirmation,
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function requestToken(token: string) {
    return axios.post<{result: boolean}>(REQUEST_TOKEN_URL, {token})
}
export function requestResetPassword(
    token: string,
    password: string,
    password_confirmation: string
) {
    return axios.post(RESET_PASSWORD_URL, {token, password, password_confirmation})
}
export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    // Check common redux folder => setupAxios
    return axios.get<ProfileResponse>(GET_USER_BY_ACCESSTOKEN_URL)
}
