import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function AuditRoutes() {


    /*Rotas para Recargas*/
    const DatatableAudits = lazy(() => import('../../pages/audits/actions/DatatableAction'))

    return (
        <Switch>

            {/*Rotas para Recargas*/}
            <Route path='/auditoria/acoes' component={DatatableAudits} />

        </Switch>
    )

}
