import {PreEventModel} from '../../../../shared/models/products/event'
import {CardAlertInformation} from '../../../../widgets/cards/CardAlertInformation'
import {DividerStoreComponent} from '../../../../widgets/dividers/DividerStoreComponent'
import {StoreDataEventInfo} from './StoreDataEventInfo'

interface Props {
    preEvent: PreEventModel
}

export function StoreDataConcluded({preEvent}: Props) {
    return (
        <>
            <CardAlertInformation
                defaultClass='info'
                icon='fas fa-info-circle'
                title='Formulário enviado, aguarde os próximos passos para acessar a plataforma em breve. Obrigado!'
            />
            <StoreDataEventInfo preEvent={preEvent} />
            {preEvent.data !== null && (
                <>
                    <DividerStoreComponent dsClass='dark' title='Contratante' />
                    <div className='row my-2'>
                        <div className='col-lg-8'>
                            <label>Contratante</label>
                            <h3>{preEvent.data.nm_contractor}</h3>
                        </div>
                        <div className='col-lg-4'>
                            <label>Documento</label>
                            <h3>{preEvent.data.doc_contractor}</h3>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <DividerStoreComponent dsClass='dark' title='Representante' />
                            <div className='row my-2'>
                                <div className='col-lg-8'>
                                    <label>Representante</label>
                                    <h3>{preEvent.data.nm_agent}</h3>
                                </div>
                                <div className='col-lg-4'>
                                    <label>Documento</label>
                                    <h3>{preEvent.data.doc_agent}</h3>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <label>E-mail</label>
                                    <h3>{preEvent.data.ds_agent_email}</h3>
                                </div>
                                <div className='col-lg-6'>
                                    <label>Telefone</label>
                                    <h3>{preEvent.data.ds_agent_phone}</h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <DividerStoreComponent dsClass='dark' title='Testemunha' />
                            <div className='row my-2'>
                                <div className='col-lg-8'>
                                    <label>Testemunha</label>
                                    <h3>{preEvent.data.nm_witness}</h3>
                                </div>
                                <div className='col-lg-4'>
                                    <label>Documento</label>
                                    <h3>{preEvent.data.doc_witness}</h3>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <label>E-mail</label>
                                    <h3>{preEvent.data.ds_witness_email}</h3>
                                </div>
                                <div className='col-lg-6'>
                                    <label>Telefone</label>
                                    <h3>{preEvent.data.ds_witness_phone}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <DividerStoreComponent dsClass='dark' title='Endereço' />
                            <div className='row my-2'>
                                <div className='col'>
                                    <label>Logradouro</label>
                                    <h3>
                                        {preEvent.data.ds_street}, {preEvent.data.ds_number}{' '}
                                        {preEvent.data.ds_complement === null
                                            ? ''
                                            : ', ' + preEvent.data.ds_complement}
                                    </h3>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <label>Bairro</label>
                                    <h3>{preEvent.data.ds_neighborhood}</h3>
                                </div>
                                <div className='col-lg-6'>
                                    <label>Cidade/Estado</label>
                                    <h3>
                                        {preEvent.data.ds_city} / {preEvent.data.ds_state}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <DividerStoreComponent dsClass='dark' title='Dados bancários' />
                            <div className='row my-2'>
                                <div className='col-lg-4'>
                                    <label>Banco</label>
                                    <h3>{preEvent.data.ds_contractor_bank}</h3>
                                </div>
                                <div className='col-lg-8'>
                                    <label>Agência/Conta</label>
                                    <h3>
                                        {preEvent.data.ds_contractor_branch} /{' '}
                                        {preEvent.data.ds_contractor_account}
                                    </h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <label>Chave PIX</label>
                                    <h3>{preEvent.data.ds_contractor_pix}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
