import axios from 'axios'
import {Formik} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {ButtonSubmit, Select, NewTextInput, NewTextInputMask} from '../../../widgets/form'
import {
    GetPreEventByUuidModel,
    ivStoreDataPreEvent,
    vsStoreDataPreEvent,
    ValuesStoreDataPreEvent,
    states,
} from './models'
import {DividerStoreComponent} from '../../../widgets/dividers/DividerStoreComponent'
import {PreEventModel} from '../../../shared/models/products/event'
import {LoadingContainer} from '../../../widgets/loading/LoadingContainer'
import {DefaultUnauthorizedContainer} from '../../../widgets/containers/DefaultUnauthorizedContainer'
import {CEPMask, CNPJMask, CPFMask, phoneMask} from '../../../masks'
import {DefaultCardWithTitleContainer} from '../../../widgets/containers/DefaultCardWithTitleContainer'
import UnauthorizedAlert from '../../../widgets/alerts/UnauthorizedAlert'
import {StoreDataConcluded} from './components/StoreDataConcluded'
import {CardAlertInformation} from '../../../widgets/cards/CardAlertInformation'
import {StoreDataEventInfo} from './components/StoreDataEventInfo'
import {ImageCropInput} from '../../../widgets/form/ImageCropInput'

interface Params {
    uuid: string
}

export default function StoreDataPreEvent() {
    const {uuid}: Params = useParams()
    const [preEvent, setPreEvent] = useState<PreEventModel>()
    const [loading, setLoading] = useState(true)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [isOpenAlert, setIsOpenAlert] = useState(false)
    const [storeConcluded, setStoreConcluded] = useState(false)

    const getData = useCallback(async () => {
        try {
            const {data} = await axios.get<GetPreEventByUuidModel>(
                `/unauthorized/products/events/pre-events/${uuid}/show`
            )
            setStoreConcluded(data.data.pre_event.data !== null)
            setPreEvent(data.data.pre_event)
        } finally {
            setLoading(false)
        }
    }, [uuid])

    useEffect(() => {
        getData()
    }, [getData])

    async function createPreEvent(values: ValuesStoreDataPreEvent) {
        try {
            setLoadingBtn(true)
            await axios.post(`unauthorized/products/events/pre-events/${uuid}/data/store`, values)
            setIsOpenAlert(true)
        } finally {
            setLoadingBtn(false)
        }
    }

    return (
        <DefaultUnauthorizedContainer>
            <DefaultCardWithTitleContainer title='Formulário de pré-evento'>
                <LoadingContainer loading={loading}>
                    {preEvent !== undefined &&
                        (storeConcluded ? (
                            <StoreDataConcluded preEvent={preEvent} />
                        ) : (
                            <>
                                <CardAlertInformation
                                    defaultClass='warning'
                                    icon='fas fa-info-circle'
                                    title='Ao enviar o formulário, o contrato será realizado
                                                com base nos dados abaixo, preencha com atenção e
                                                qualquer divergência apresentada nas informações,
                                                contate a Kachin antes de preencher. Obrigado!'
                                />
                                <StoreDataEventInfo preEvent={preEvent} />
                                <Formik
                                    initialValues={ivStoreDataPreEvent}
                                    validationSchema={vsStoreDataPreEvent}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={(values) => {
                                        createPreEvent(values)
                                    }}
                                >
                                    {(props) => {
                                        return (
                                            <form onSubmit={props.handleSubmit} noValidate>
                                                <DividerStoreComponent
                                                    dsClass='dark'
                                                    title='Imagem do evento'
                                                />
                                                <div className='row mb-5'>
                                                    <div className='col-lg-5 mb-5 min-h-250px'>
                                                        <ImageCropInput
                                                            backgroundImage='/media/logos/logo-event.png'
                                                            name='im_banner_path'
                                                            label=''
                                                            formik={props}
                                                        />
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <label>Observações</label>
                                                        <ul className='text-justify'>
                                                            <li className='h6'>
                                                                Para alterar a imagem, apenas clique
                                                                no ícone de câmera, selecione a
                                                                imagem, enquadre no espaço
                                                                disponível e confirme.
                                                            </li>
                                                            <li className='h6'>
                                                                Recomendamos o uso de uma imagem
                                                                quadrada, contendo 512x512 pixels.
                                                            </li>
                                                            <li className='h6'>
                                                                A imagem adicionada irá aparecer
                                                                para os usuários do aplicativo
                                                                Kachin.
                                                            </li>
                                                            <li className='h6'>
                                                                Caso nenhuma imagem seja adicionada,
                                                                será utilizado a logo da Kachin,
                                                                conforme apresentado.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <DividerStoreComponent
                                                    dsClass='dark'
                                                    title='Contratante'
                                                />
                                                <div className='row'>
                                                    <div className='col-lg-2'>
                                                        <Select
                                                            name='type_contractor'
                                                            label='Tipo de pessoa'
                                                            formik={props}
                                                            required
                                                        >
                                                            <option key='PF' value='PF'>
                                                                Pessoa Física
                                                            </option>
                                                            <option key='PJ' value='PJ'>
                                                                Pessoa Jurídica
                                                            </option>
                                                        </Select>
                                                    </div>
                                                    {props.values.type_contractor === 'PF' && (
                                                        <>
                                                            <div className='col-lg-7 mb-5'>
                                                                <NewTextInput
                                                                    name='nm_contractor'
                                                                    label='Nome Completo'
                                                                    placeholder='Entre com o nome completo'
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='col-lg-3 mb-5'>
                                                                <NewTextInputMask
                                                                    label='CPF'
                                                                    name='doc_contractor'
                                                                    placeholder='Entre com o CPF'
                                                                    mask={CPFMask}
                                                                    required
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    {props.values.type_contractor === 'PJ' && (
                                                        <>
                                                            <div className='col-lg-7 mb-5'>
                                                                <NewTextInput
                                                                    name='nm_contractor'
                                                                    label='Razão Social'
                                                                    placeholder='Entre com a Razão Social'
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='col-lg-3 mb-5'>
                                                                <NewTextInputMask
                                                                    label='CNPJ'
                                                                    name='doc_contractor'
                                                                    placeholder='Entre com o CNPJ'
                                                                    mask={CNPJMask}
                                                                    required
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <DividerStoreComponent
                                                    dsClass='dark'
                                                    title='Endereço'
                                                />
                                                <div className='row'>
                                                    <div className='col-12 col-md-3 mb-5'>
                                                        <NewTextInputMask
                                                            mask={CEPMask}
                                                            name='ds_zipcode'
                                                            label='CEP'
                                                            placeholder='Entre com CEP'
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-5 mb-5'>
                                                        <NewTextInput
                                                            name='ds_street'
                                                            label='Logradouro'
                                                            placeholder='Entre com o logradouro'
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-2 mb-5'>
                                                        <NewTextInput
                                                            name='ds_number'
                                                            label='N°'
                                                            placeholder='Ex: 10'
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-2 mb-5'>
                                                        <NewTextInput
                                                            name='ds_complement'
                                                            label='Complemento'
                                                            placeholder='Ex: Ap. 01'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12 col-md-4 mb-5'>
                                                        <NewTextInput
                                                            name='ds_neighborhood'
                                                            label='Bairro'
                                                            placeholder='Entre com o bairro'
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <NewTextInput
                                                            name='ds_city'
                                                            label='Cidade'
                                                            placeholder='Entre com a cidade'
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-2'>
                                                        <Select
                                                            name='ds_state'
                                                            label='Estado'
                                                            formik={props}
                                                            required
                                                        >
                                                            {states.map((item) => (
                                                                <option
                                                                    key={`state-${item}`}
                                                                    value={item}
                                                                >
                                                                    {item}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <DividerStoreComponent
                                                    dsClass='dark'
                                                    title='Dados bancários'
                                                />
                                                <div className='row mb-5'>
                                                    <div className='col-12 col-md-3'>
                                                        <NewTextInput
                                                            label='Instituição'
                                                            name='ds_contractor_bank'
                                                            placeholder='Ex: PagBank'
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-2'>
                                                        <NewTextInput
                                                            label='Agência'
                                                            name='ds_contractor_branch'
                                                            placeholder='Ex: 0001'
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-3'>
                                                        <NewTextInput
                                                            label='Conta com dígito'
                                                            name='ds_contractor_account'
                                                            placeholder='Ex: 159753-4'
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <NewTextInput
                                                            label='Chave PIX'
                                                            name='ds_contractor_pix'
                                                            placeholder='Entre com a chave'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <DividerStoreComponent
                                                            dsClass='dark'
                                                            title='Representante'
                                                        />
                                                        <div className='row'>
                                                            <div className='col-lg-8 mb-5'>
                                                                <NewTextInput
                                                                    name='nm_agent'
                                                                    label='Nome Completo'
                                                                    placeholder='Entre com o nome completo'
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='col-lg-4 mb-5'>
                                                                <NewTextInputMask
                                                                    label='CPF'
                                                                    name='doc_agent'
                                                                    placeholder='Entre com o CPF'
                                                                    mask={CPFMask}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-lg-6 mb-5'>
                                                                <NewTextInput
                                                                    name='ds_agent_email'
                                                                    label='E-mail'
                                                                    type='email'
                                                                    placeholder='Entre com o e-mail'
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='col-lg-6 mb-5'>
                                                                <NewTextInputMask
                                                                    label='Telefone'
                                                                    name='ds_agent_phone'
                                                                    placeholder='Entre com o telefone'
                                                                    mask={phoneMask}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <DividerStoreComponent
                                                            dsClass='dark'
                                                            title='Testemunha'
                                                        />
                                                        <div className='row'>
                                                            <div className='col-lg-8 mb-5'>
                                                                <NewTextInput
                                                                    name='nm_witness'
                                                                    label='Nome Completo'
                                                                    placeholder='Entre com o nome completo'
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='col-lg-4 mb-5'>
                                                                <NewTextInputMask
                                                                    label='CPF'
                                                                    name='doc_witness'
                                                                    placeholder='Entre com o CPF'
                                                                    mask={CPFMask}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-lg-6 mb-5'>
                                                                <NewTextInput
                                                                    name='ds_witness_email'
                                                                    label='E-mail'
                                                                    type='email'
                                                                    placeholder='Entre com o e-mail'
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='col-lg-6 mb-5'>
                                                                <NewTextInputMask
                                                                    label='Telefone'
                                                                    name='ds_witness_phone'
                                                                    placeholder='Entre com o telefone'
                                                                    mask={phoneMask}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ButtonSubmit
                                                    label='Enviar formulário'
                                                    loading={loadingBtn}
                                                    textClass='center'
                                                />
                                            </form>
                                        )
                                    }}
                                </Formik>
                                <UnauthorizedAlert
                                    onClose={() => {
                                        setIsOpenAlert(false)
                                        window.location.reload()
                                    }}
                                    title='Formulário enviado com sucesso! Contrato para assinatura, em breve nos e-mails informados.'
                                    open={isOpenAlert}
                                />
                            </>
                        ))}
                </LoadingContainer>
            </DefaultCardWithTitleContainer>
        </DefaultUnauthorizedContainer>
    )
}
