import React, {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {AuditRoutes} from './internal/AuditRoutes'

import {DashboardRoutes} from './internal/DashboardRoutes'
import {ProductRoutes} from './internal/ProductRoutes'
import {AccessRoutes} from './internal/AccessRoutes'
import {MobileRoutes} from './internal/MobileRoutes'
import {FinancialRoutes} from './internal/FinancialRoutes'
import {WarehouseRoutes} from './internal/WarehouseRoutes'
import {EventRoutes} from './internal/EventRoutes'

export function PrivateRoutes() {
    const AccountPage = lazy(() => import('../pages/accounts/AccountPage'))

    return (
        <Suspense fallback={<FallbackView />}>
            <Switch>
                <Route path='/perfil' component={AccountPage} />

                {/*Rotas para Painel*/}
                <Route path='/painel' component={DashboardRoutes} />

                {/*Rotas para Produtos*/}
                <Route path='/produtos' component={ProductRoutes} />

                {/*Rotas para Acessos*/}
                <Route path='/acessos' component={AccessRoutes} />

                {/*Rotas para App*/}
                <Route path='/aplicativo' component={MobileRoutes} />

                {/*Rotas para Financeiro*/}
                <Route path='/financeiro' component={FinancialRoutes} />

                {/*Rotas para Auditoria*/}
                <Route path='/auditoria' component={AuditRoutes} />

                {/*Rotas para Almoxarifado*/}
                <Route path='/almoxarifado' component={WarehouseRoutes} />

                {/*Rotas para Event*/}
                <Route path='/event' component={EventRoutes} />

                <Redirect from='/auth' to='/painel/administrativo' />
                <Redirect exact from='/' to='/painel/administrativo' />
                <Redirect to='error/404' />
            </Switch>
        </Suspense>
    )
}
