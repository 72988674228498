export function checkCpf(document: string) {
    document = document.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (document.length !== 11 || /^(\d)\1+$/.test(document)) return false;

    let sum = 0, remainder;
    for (let i = 1; i <= 9; i++) sum += parseInt(document[i - 1]) * (11 - i);
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(document[9])) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum += parseInt(document[i - 1]) * (12 - i);
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(document[10])) return false;

    return true;
}

export function checkCnpj(document: string) {
    document = document.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (document.length !== 14 || /^(\d)\1+$/.test(document)) return false;

    let length = document.length - 2;
    let numbers = document.substring(0, length);
    let digits = document.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers.charAt(length - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;

    length += 1;
    numbers = document.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers.charAt(length - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
}