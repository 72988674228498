import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './pages/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type Props = {
    basename: string
}

const App: React.FC<Props> = ({basename}) => {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={basename}>
                <LayoutProvider>
                    <AuthInit>
                        <Routes />
                        <ToastContainer autoClose={5000} />
                    </AuthInit>
                </LayoutProvider>
            </BrowserRouter>
        </Suspense>
    )
}

export {App}
