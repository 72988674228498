import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function FinancialRoutes() {
    /*Rotas para Usuários*/
    const DatatableTransactions = lazy(() => import('../../pages/financial/transactions/DatatableTransaction'))
    const ShowTransaction = lazy(() => import('../../pages/financial/transactions/ShowTransaction'))

    return (
        <Switch>
            {/*Rotas para Usuários*/}
            <Route path='/financeiro/transacoes/:uuid' component={ShowTransaction} />
            <Route path='/financeiro/transacoes' component={DatatableTransactions} />
        </Switch>
    )
}