import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function EventRoutes() {
    /*Rotas para Resumo*/
    const DatatableEventReportSummary = lazy(
        () => import('../../pages/event/reports/DatatableEventReportSummary')
    )

    /*Rotas para Pré-Evento*/
    const DatatablePreEvent = lazy(() => import('../../pages/event/pre_events/DatatablePreEvent'))
    const StorePreEvent = lazy(() => import('../../pages/event/pre_events/StorePreEvent'))
    const ShowPreEvent = lazy(() => import('../../pages/event/pre_events/ShowPreEvent'))

    return (
        <Switch>
            {/*Rotas para Resumo*/}
            <Route path='/event/relatorios/resumo' component={DatatableEventReportSummary} />

            {/*Rotas para Pré-Evento*/}
            <Route path='/event/pre-eventos/cadastrar' component={StorePreEvent} />
            <Route path='/event/pre-eventos/:id' component={ShowPreEvent} />
            <Route path='/event/pre-eventos' component={DatatablePreEvent} />
        </Switch>
    )
}
