import clsx from 'clsx'
import {Field, FieldProps} from 'formik'

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string
    label?: string
    value?: string | undefined
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
    required?: boolean
}
export function TextArea({
    name,
    label,
    value,
    onChange,
    onBlur,
    required = false,
    ...props
}: Props) {
    return (
        <>
            {label && (
                <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
            <Field>
                {({form}: FieldProps) => (
                    <>
                        <textarea
                            {...props}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': form?.touched[name] && form?.errors[name],
                                },
                                {
                                    'is-valid': form?.touched[name] && form?.errors[name],
                                }
                            )}
                            id={name}
                            name={name}
                            value={value || form.values[name]}
                            onChange={onChange || form.handleChange}
                            onBlur={onBlur || form.handleBlur}
                        />
                        {form.touched[name] && form.errors[name] && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                        {form.errors[name]}
                                    </span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Field>
        </>
    )
}
