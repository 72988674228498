import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function ProductRoutes() {
    /*Rotas para Eventos*/
    const DatatableEvent = lazy(() => import('../../pages/products/events/DatatableEvent'))
    const ShowEvent = lazy(() => import('../../pages/products/events/ShowEvent'))
    const StoreEvent = lazy(() => import('../../pages/products/events/StoreEvent'))

    /*Rotas para Food*/
    const DatatableFood = lazy(() => import('../../pages/products/food/DatatableFood'))
    const ShowFood = lazy(() => import('../../pages/products/food/ShowFood'))
    const StoreFood = lazy(() => import('../../pages/products/food/StoreFood'))

    return (
        <Switch>
            {/*Rotas para Eventos*/}
            <Route path='/produtos/eventos/cadastrar' component={StoreEvent} />
            <Route path='/produtos/eventos/:id' component={ShowEvent} />
            <Route path='/produtos/eventos' component={DatatableEvent} />

            {/*Rotas para Food*/}
            <Route path='/produtos/food/cadastrar' component={StoreFood} />
            <Route path='/produtos/food/:id' component={ShowFood} />
            <Route path='/produtos/food' component={DatatableFood} />
        </Switch>
    )
}
